import LocalizedStrings from 'react-localization';

import { en } from '../localization/en';
import { fr } from '../localization/fr';

export const key = new LocalizedStrings({
  en: en,
  fr: fr,
});

// Forcing language for debug purpose
// key.setLanguage('fr');

export const $ = (stringKey, ...rest) => key.formatString(stringKey, ...rest);
