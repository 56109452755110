import React, { useContext, useState } from 'react'
import { Button, Input, Col, Form, Row } from 'antd'
import { auth } from '../../services/firebase'
import { Redirect } from 'react-router-dom'
import { UserContext } from '../../providers/UserProvider'
import Loading from '../Loading'
import { $, key } from '../../services/localization'
import logo from '../../assets/logo.png'
import './login.less'

const StandalonePasswordLoginComponent = () => {
    const [errorMessage, setErrorMessage] = useState()
    const user = useContext(UserContext)

    const attemptLogin = async (formData) => {
        try {
            // Hardcoded email for login
            await auth.signInWithEmailAndPassword("test@coteries.com", formData.password)
            setErrorMessage()
        } catch (error) {
            console.log(error)
            if (error.code === 'auth/wrong-password' || error.code === 'auth/user-not-found') {
                setErrorMessage($(key.firebase_signon_errorSignInPassword))
            } else {
                setErrorMessage($(key.firebase_signon_errorSignInFailed))
            }
        }
    }

    const renderFormLogin = () => {
        return (
            <Row align="center"
                 className="login-container">
                <Col xs={12}
                     md={6}>
                    <Row align="center">                        
                        <img src={logo}
                             className="logo"
                             alt="matchME Logo" />
                    </Row>
                    <Row align="center"
                         className="password-login">
                        <Col>
                            {
                                errorMessage &&
                                <Row align="center">
                                    <Form.Item>
                                        <span className="error-message-login">{errorMessage}</span>
                                    </Form.Item>
                                </Row>
                            }
                            <Form name="formLogin"
                                  className="form-login"
                                  layout="vertical"
                                  hideRequiredMark="true"
                                  colon="false"
                                  onFinish={attemptLogin}>
                                <Form.Item
                                    name="password"
                                    label={$(key.pleaseEnterPassword)}
                                    rules={[
                                        {
                                            required: true,
                                            message: `- ${$(key.firebase_signon_errorRequiredPassword)}`
                                        }
                                    ]}>
                                    <Input.Password />
                                </Form.Item>
                                <Form.Item>
                                    <Row justify="end">
                                        <Button type="primary"
                                                className="input-email-save"
                                                htmlType="submit">
                                            {$(key.firebase_signon_signIn)}
                                        </Button>
                                    </Row>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

    if (user === null) {
        return (
            <Loading />
        )
    } else if (user) {
        return (
            <Redirect to="/demo" />
        )
    } else {
        // Once we have the email, if a user exists, ask for password to try to login
        return renderFormLogin()
    }
}

export default StandalonePasswordLoginComponent
