export const fr = {
  firebase_signon_signIn: `Se connecter`,
  firebase_signon_signOut: `Déconnexion`,
  firebase_signon_signOn: `S'inscrire`,
  firebase_signon_test: `Test`,
  firebase_signon_confirmEmail: `Courriel de vérification envoyé, veuillez vérifier votre courriel`,
  firebase_signon_backToHome: `Retour à la page d'accueil`,
  firebase_signon_termsAndPrivacy: `Accepter notre {0} et notre {1}`,
  firebase_signon_termsCta: `Conditions de service`,
  firebase_signon_privacyCta: `Politique de confidentialité`,
  firebase_signon_newsletterAccept: `Recevoir des nouvelles de la newsletter`,
  firebase_signon_signInGoogle: `Connectez-vous avec Google`,
  firebase_signon_signInEmail: `Connectez-vous avec votre e-mail`,
  firebase_signon_createAccount: `Créer un compte`,
  firebase_signon_email: `Courriel`,
  firebase_signon_choosePassword: `Choisissez un mot de passe`,
  firebase_signon_cancel: `Annuler`,
  firebase_signon_save: `Sauvegarder`,
  firebase_signon_next: `Suivant`,
  firebase_signon_byCotinuingTermsPriv: `En continuant, vous indiquez que vous acceptez nos {0} et {1}.`,
  firebase_signon_errorInvalidEmail: `Veuillez entrer un courriel valide`,
  firebase_signon_errorRequiredEmail: `Veuillez saisir votre adresse électronique`,
  firebase_signon_password: `Mot de passe`,
  firebase_signon_troubleSigningIn: `Des problèmes pour s'inscrire ?`,
  firebase_signon_errorRequiredPassword: `Veuillez entrer votre mot de passe`,
  firebase_signon_errorMinPassword: `Veuillez saisir au moins 8 caractères`,
  firebase_signon_errorSpecCharPassword: `Doit avoir au moins un caractère spécial`,
  firebase_signon_errorUpperCharPassword: `Doit avoir au moins un caractère majuscule`,
  firebase_signon_errorNumberPassword: `Doit avoir au moins un numéro`,
  firebase_signon_errorRequiredTermsPrivacy: `Veuillez accepter les conditions de service`,
  firebase_signon_signInFacebook: `Connectez-vous avec Facebook`,
  firebase_signon_firstName: `Prénom`,
  firebase_signon_lastName: `Nom de famille`,
  firebase_signon_errorRequiredFirstName: `Veuillez saisir votre prénom`,
  firebase_signon_errorRequiredLastName: `Veuillez entrer votre nom de famille`,
  firebase_signon_errorSignInFailed: `Une erreur inattendue s'est produite, la connexion a échoué`,
  firebase_signon_errorSignInExistingAccount: `Vous avez déjà un compte avec cet e-mail. Veuillez vous connecter ici :`,
  firebase_signon_errorSignInPassword: `L'adresse électronique et le mot de passe que vous avez saisis ne correspondent pas`,
  firebase_signon_errorEmailExists: `L'adresse électronique est déjà utilisée par un autre compte.`,
  firebase_signon_errorCreateAccountFailed: `Une erreur inattendue s'est produite, la création du compte a échoué`,
  firebase_signon_recoverPassword: `Récupérer le mot de passe`,
  firebase_signon_instructionsEmailResetPassword: `Recevez les instructions envoyées à ce courriel qui expliquent comment réinitialiser votre mot de passe`,
  firebase_signon_checkEmail: `Vérifiez votre courrier électronique`,
  firebase_signon_followInstructionsRecover: `Si un compte avec cet e-mail existe, suivez les instructions envoyées à {0} pour récupérer votre mot de passe`,
  firebase_signon_send: `Envoyer`,
  firebase_signon_errorRecoverPasswordFailed: `Une erreur inattendue s'est produite, la récupération du mot de passe a échoué`,
  firebase_signon_genericError: `Oups ! Quelque chose a mal tourné. Essayez plus tard, s'il vous plaît.`,
  firebase_signon_error: `Erreur`,
  firebase_signon_formNewPassword: `Nouveau mot de passe`,
  firebase_signon_changePasswordChanged: `Mot de passe modifié`,
  firebase_signon_changePasswordNowSignIn: `Vous pouvez maintenant vous connecter avec votre nouveau mot de passe`,
  firebase_signon_changePasswordErrorTitle: `Essayez de réinitialiser votre mot de passe`,
  firebase_signon_changePasswordTitle: `Réinitialiser votre mot de passe`,
  firebase_signon_changePasswordSuccessTitle: `Mot de passe modifié`,
  firebase_signon_changePasswordSuccessSubtitle: `Vous pouvez maintenant vous connecter avec votre nouveau mot de passe`,
  firebase_signon_verifyYourEmailErrorTitle: `Essayez de vérifier à nouveau votre courrier électronique`,
  firebase_signon_verifyYourEmailSuccessTitle: `Votre adresse électronique a été vérifiée`,
  firebase_signon_verifyYourEmailSuccessSubtitle: `Merci ! Vous pouvez maintenant profiter pleinement de l'expérience.`,
  firebase_signon_verifyYourEmailSendAgain: `Envoyer à nouveau un courriel`,
  firebase_signon_recoverEmailSuccessTitle: `Vérifiez votre courrier électronique`,
  firebase_signon_recoverEmailSuccessSubtitle: `Suivez les instructions envoyées à {0} pour récupérer votre mot de passe.`,
  firebase_signon_recoverEmailTitle: `Adresse électronique mise à jour`,
  firebase_signon_recoverEmailSubtitle: `Votre adresse électronique de connexion a été modifiée en {0}.`,
  firebase_signon_recoverEmailDescription: `Si vous n'avez pas modifié votre adresse électronique de connexion, il est possible que quelqu'un essaie d'accéder à votre compte et vous devez {0}`,
  firebase_signon_recoverEmailDescriptionCta: `changez votre mot de passe immédiatement`,
  firebase_signon_recoverEmailErrorTitle: `Impossible de mettre à jour votre adresse électronique`,
  firebase_signon_recoverEmailErrorSubtitle: `Il y a eu un problème de changement de votre e-mail de connexion.`,
  firebase_signon_signInTwitter: `Connectez-vous avec Twitter`,
  firebase_signon_signInGitHub: `Connectez-vous à GitHub`,
  firebase_signon_myProfile: `Mon profil`,
  firebase_signon_signInPhone: `S'inscrire par téléphone`,
  firebase_signon_verifyPhoneTitle: `Vérifiez votre numéro de téléphone`,
  firebase_signon_verifyPhoneDescription: `Entrez le code à 6 chiffres que nous avons envoyé à {0}`,
  firebase_signon_sixDigitCode: `Code à 6 chiffres`,
  firebase_signon_continue: `Continuer`,
  firebase_signon_loginPhoneTitle: `Entrez votre numéro de téléphone`,
  firebase_signon_phoneNumber: `Numéro de téléphone (avec l'indicatif du pays)`,
  firebase_signon_verify: `Vérifier`,
  firebase_signon_loginPhoneDisclaimer: `En appuyant sur Vérifier, un SMS peut être envoyé. Les tarifs des messages et des données peuvent s'appliquer.`,
  firebase_signon_errorWrongCode: `Code erroné. Veuillez réessayer.`,
  firebase_signon_errorValidPhoneNumber: `Veuillez entrer un numéro de téléphone valide (y compris le code du pays)`,
  firebase_signon_errorCodeLength: `Le code doit comporter 6 chiffres`,
  copyright: `2020 CloseUP Tous droits réservés`,
  uploadPicture: `Téléchargez une photo`,
  newPicture: `Nouvelle image`,
  explanationTitleDe: `Erklärung`,
  lessPronouncedDe: `Wenig ausgeprägt`,
  veryPronouncedDe: `Stark ausgeprägt`,
  characteristicsDe: `Eigenschaften`,
  explanationTitleEn: `Explanation`,
  lessPronouncedEn: `Less pronounced`,
  veryPronouncedEn: `Very pronounced`,
  characteristicsEn: `Characteristics`,
  errorGeneric: `Oups ! Quelque chose a mal tourné. Veuillez réessayer plus tard.`,
  errorNoFace: `Aucun visage trouvé, veuillez télécharger une autre photo.`,
  errorMultipleFaces: `Plusieurs visages trouvés dans la photo, veuillez télécharger une photo avec une seule personne.`,
  errorDetectingFace: `Un visage a été détecté, veuillez essayer avec une autre photo`,
  pleaseEnterPassword: `Veuillez entrer le mot de passe pour accéder à la démo`,
  matchMeDescription: `La version alpha de MatchME reconnaît les traits de personnalité à partir de votre visage.`,
  uploadButtonLabel: `Téléchargez votre portrait ici !`,
  newPortrait: `Téléchargez un nouveau portrait ici !`,
}