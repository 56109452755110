import { Spin } from 'antd'
import React from 'react'
import '../App.less'

const Loading = () => {
  return (
    <div className="loader">
      <Spin size='large' />
    </div>
  )
}

export default Loading