import { notification } from 'antd'
import { $, key } from '../services/localization'

export const handleError = (e, description, callback) => {
  let message = description || $(key.firebase_signon_genericError)
  if (e) {
    if (e.response) {
      message = e.response.message
    } else if (e.message) {
      message = e.message
    }
  }
  notification['error']({
    message: $(key.firebase_signon_error),
    description: message
  })
  if (callback) callback()
}