export const en = {
  firebase_signon_signIn: `Sign In`,
  firebase_signon_signOut: `Sign Out`,
  firebase_signon_signOn: `Sign On`,
  firebase_signon_test: `Test`,
  firebase_signon_confirmEmail: `Verification email sent, please check your email`,
  firebase_signon_backToHome: `Back to Home`,
  firebase_signon_termsAndPrivacy: `Accept our {0} and our {1}`,
  firebase_signon_termsCta: `Terms of Service`,
  firebase_signon_privacyCta: `Privacy Policy`,
  firebase_signon_newsletterAccept: `Receive news from newsletter`,
  firebase_signon_signInGoogle: `Sign in with Google`,
  firebase_signon_signInEmail: `Sign in with email`,
  firebase_signon_createAccount: `Create account`,
  firebase_signon_email: `Email`,
  firebase_signon_choosePassword: `Choose Password`,
  firebase_signon_cancel: `Cancel`,
  firebase_signon_save: `Save`,
  firebase_signon_next: `Next`,
  firebase_signon_byCotinuingTermsPriv: `By continuing, you are indicating that you accept our {0} and {1}.`,
  firebase_signon_errorInvalidEmail: `Please input a valid email`,
  firebase_signon_errorRequiredEmail: `Please input your email`,
  firebase_signon_password: `Password`,
  firebase_signon_troubleSigningIn: `Trouble signing in?`,
  firebase_signon_errorRequiredPassword: `Please enter your password`,
  firebase_signon_errorMinPassword: `Please enter at least 8 characters`,
  firebase_signon_errorSpecCharPassword: `Should have at least one special character`,
  firebase_signon_errorUpperCharPassword: `Should have at least one uppercase character`,
  firebase_signon_errorNumberPassword: `Should have at least one number`,
  firebase_signon_errorRequiredTermsPrivacy: `Please accept the terms of service`,
  firebase_signon_signInFacebook: `Sign in with Facebook`,
  firebase_signon_firstName: `First name`,
  firebase_signon_lastName: `Last name`,
  firebase_signon_errorRequiredFirstName: `Please enter your first name`,
  firebase_signon_errorRequiredLastName: `Please enter your last name`,
  firebase_signon_errorSignInFailed: `An unexpected error ocurred, sign in failed`,
  firebase_signon_errorSignInExistingAccount: `You already have an account with that email. Please sign in here:`,
  firebase_signon_errorSignInPassword: `The email and password you entered don't match`,
  firebase_signon_errorEmailExists: `The email address is already in use by another account.`,
  firebase_signon_errorCreateAccountFailed: `An unexpected error ocurred, account creation failed`,
  firebase_signon_recoverPassword: `Recover Password`,
  firebase_signon_instructionsEmailResetPassword: `Get instructions sent to this email that explain how to reset your password`,
  firebase_signon_checkEmail: `Check your email`,
  firebase_signon_followInstructionsRecover: `If an account with that email exists, follow the instructions sent to {0} to recover your password`,
  firebase_signon_send: `Send`,
  firebase_signon_errorRecoverPasswordFailed: `An unexpected error ocurred, password recovery failed`,
  firebase_signon_genericError: `Oops! Something went wrong. Please try later.`,
  firebase_signon_error: `Error`,
  firebase_signon_formNewPassword: `New password`,
  firebase_signon_changePasswordChanged: `Password changed`,
  firebase_signon_changePasswordNowSignIn: `You can now sign in with your new password`,
  firebase_signon_changePasswordErrorTitle: `Try resetting your password again`,
  firebase_signon_changePasswordTitle: `Reset your password`,
  firebase_signon_changePasswordSuccessTitle: `Password changed`,
  firebase_signon_changePasswordSuccessSubtitle: `You can now sign in with your new password`,
  firebase_signon_verifyYourEmailErrorTitle: `Try verifying your email again`,
  firebase_signon_verifyYourEmailSuccessTitle: `Your email has been verified`,
  firebase_signon_verifyYourEmailSuccessSubtitle: `Thanks! You can now enjoy fully the experience.`,
  firebase_signon_verifyYourEmailSendAgain: `Send email again`,
  firebase_signon_recoverEmailSuccessTitle: `Check your email`,
  firebase_signon_recoverEmailSuccessSubtitle: `Follow the instructions sent to {0} to recover your password.`,
  firebase_signon_recoverEmailTitle: `Updated email address`,
  firebase_signon_recoverEmailSubtitle: `Your sign-in email address has been changed back to {0}`,
  firebase_signon_recoverEmailDescription: `If you did not change your sign-in email, it is possible someone is trying to access your account and you should {0}`,
  firebase_signon_recoverEmailDescriptionCta: `change your password right away`,
  firebase_signon_recoverEmailErrorTitle: `Unable to update your email address`,
  firebase_signon_recoverEmailErrorSubtitle: `There was a problem changing your sign-in email back.`,
  firebase_signon_signInTwitter: `Sign in with Twitter`,
  firebase_signon_signInGitHub: `Sign in with GitHub`,
  firebase_signon_myProfile: `My Profile`,
  firebase_signon_signInPhone: `Sign in with phone`,
  firebase_signon_verifyPhoneTitle: `Verify your phone number`,
  firebase_signon_verifyPhoneDescription: `Enter the 6-digit code we sent to {0}`,
  firebase_signon_sixDigitCode: `6-digit code`,
  firebase_signon_continue: `Continue`,
  firebase_signon_loginPhoneTitle: `Enter your phone number`,
  firebase_signon_phoneNumber: `Phone number (with country code)`,
  firebase_signon_verify: `Verify`,
  firebase_signon_loginPhoneDisclaimer: `By tapping Verify, an SMS may be sent. Message & data rates may apply.`,
  firebase_signon_errorWrongCode: `Wrong code. Please try again.`,
  firebase_signon_errorValidPhoneNumber: `Please enter a valid phone number (including the country code)`,
  firebase_signon_errorCodeLength: `Code should be 6-digits long`,
  copyright: `© 2020 CloseUP All rights reserved`,
  uploadPicture: `Upload a picture`,
  newPicture: `New Picture`,
  explanationTitleDe: `Erklärung`,
  lessPronouncedDe: `Wenig ausgeprägt`,
  veryPronouncedDe: `Stark ausgeprägt`,
  characteristicsDe: `Eigenschaften`,
  explanationTitleEn: `Explanation`,
  lessPronouncedEn: `Less pronounced`,
  veryPronouncedEn: `Very pronounced`,
  characteristicsEn: `Characteristics`,
  errorGeneric: `Oops! Something went wrong. Please try again later.`,
  errorNoFace: `No face found, please upload another picture.`,
  errorMultipleFaces: `Multiple faces found in the picture, please upload a photo with only one person.`,
  errorDetectingFace: `Error detecting face, please try with another picture`,
  pleaseEnterPassword: `Please enter the password to access the demo`,
  matchMeDescription: `MatchME alpha version recognizes personality traits based on your face.`,
  uploadButtonLabel: `Upload your portrait here!`,
  newPortrait: `Upload a new portrait here!`,
}