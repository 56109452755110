import React, { Component, createContext } from 'react'
import { auth, db } from '../services/firebase'
import firebase from 'firebase/app'

export const UserContext = createContext({ user: null })

export const generateUserDocument = async (user, additionalData) => {
  if (!user) return

  const userRef = db.doc(`users/${user.uid}`)
  const snapshot = await userRef.get()

  if (!snapshot.exists || additionalData) {
    const { email, displayName, photoURL, phoneNumber } = user
    try {
      const editedTimestamp = firebase.firestore.Timestamp.fromDate(new Date())
      await userRef.set({
        editedTimestamp,
        displayName,
        email,
        photoURL,
        phoneNumber,
        ...additionalData
      })
    } catch (error) {
      console.error("Error creating user document", error)
    }
  }
  return getUserDocument(user.uid)
}

export const getUserDocument = async uid => {
  if (!uid) return null
  try {
    const userDocument = await db.doc(`users/${uid}`).get()
    return {
      uid,
      ...userDocument.data()
    }
  } catch (error) {
    console.error("Error fetching user", error)
  }
}

class UserProvider extends Component {
  state = {
    user: null
  }

  componentDidMount = async () => {
    auth.onAuthStateChanged(async userAuth => {
      const user = await generateUserDocument(userAuth)
      this.setState({ user })
    })
  }


  render() {
    const { user } = this.state

    return (
      <UserContext.Provider value={user}>
        {this.props.children}
      </UserContext.Provider>
    )
  }
}

export default UserProvider
