export const mappings = {
    "Du wählst dein Gegenüber sehr genau aus. Du brauchst Zeit, um Menschen kennenzulernen, das Vertrauen aufzubauen und dein inneres OK für nachhaltige und zwischenmenschliche Beziehungen zu geben. 🤔": {
        de_title: "Harmonie",
        de_number: 1,
        de_description: "Nicht so harmoniebedürftig, braucht Zeit für sich",
        en_title: "Harmony",
        en_number: 1,
        en_description: "Harmony not so important, needs time alone"
    },
    "Manchmal offen, zum Teil auch zurückhaltend, pflegst Du zwischenmenschliche Kontakte und versuchst sowohl über dein Gefühl wie auch über das Gespräch dein Gegenüber kennenzulernen oder abzuchecken. 🤗": {
        de_title: "Harmonie",
        de_number: 2,
        de_description: "Harmonie mit Freiheitsempfinden",
        en_title: "Harmony",
        en_number: 2,
        en_description: "Desire for both, harmony and independence"
    },
    "Du bist voller Gefühle, intuitiv und impulsiv, manchmal auch auf eine gute Art naiv. Du liebst das Zusammenleben mit anderen Menschen, den persönlichen und offenen Austausch mit deinen Liebsten. Du bist emotional und begeisterungsfähig. Manchmal steckst du mit deinem Enthusiasmus andere an, manchmal geht es aber auch ein Bisschen zu weit mit deiner Begeisterung. 😀😗": {
        de_title: "Harmonie",
        de_number: 3,
        de_description: "Mag Harmonie gerne, ist gefühlsbetont",
        en_title: "Harmony",
        en_number: 3,
        en_description: "Seeks harmony, is feelings oriented"
    },
    "Du bist wenig materiell und viel eher fokussiert auf deine inneren Werte. Genügsam oder gar bescheiden meisterst du den Alltag. 😇": {
        de_title: "Materiell",
        de_number: 1,
        de_description: "Wenig materiell, Luxus steht nicht im Vordegrund",
        en_title: "Materialism",
        en_number: 1,
        en_description: "Not materialistic, focus is not on luxury"
    },
    "Du hast einen Hang zu schönen Dingen, bist aber grundsätzlich nicht an Marken gebunden oder materiell. Du lässt dich aber gerne von schönen Dingen begeistern oder verführen. ☀️✨": {
        de_title: "Materiell",
        de_number: 2,
        de_description: "Wertigkeit wichtig, aber es muss kein Luxus sein",
        en_title: "Materialism",
        en_number: 2,
        en_description: "Focus on quality and value for money"
    },
    "Dir gefallen wertige Dinge, Geld kann für dich eine Rolle spielen. Du bist wertmäßig (es muss nicht unbedingt Geld sein) fokussiert und engagiert. Themen wie Wachstum, Optimierung und Erweiterung sind für dich ein Thema, und zwar rational wie auch emotional. 👏": {
        de_title: "Materiell",
        de_number: 3,
        de_description: "Mag Luxus und wertige Dinge",
        en_title: "Materialism",
        en_number: 3,
        en_description: "Enjoys luxury and high-end things"
    },
    "Du bist eher introvertiert oder kommunizierst bewusst zurückhaltend. Wenn du dich ausdrückst, dann immer mit Substanz – bewusst und gezielt, möglichst auf den Punkt. 🤫 Empfehlung: Öffne dich ruhig noch ein wenig mehr, du kannst dabei meistens nur gewinnen. 🤸‍♀️🤸‍♂️": {
        de_title: "Genuss",
        de_number: 1,
        de_description: "Zielstrebig, bewusster Genuss",
        en_title: "Enjoyment",
        en_number: 1,
        en_description: "Goal-oriented, conscious focus on enjoyment"
    },
    "Du kommunizierst locker und entspannt. Du gehst die meisten Themen agil und offen an. 😊 Empfehlung: In der Ruhe liegt die Kraft, hinter deiner kumpelhaften Art steckt ganz viel Tiefe. 🤸‍♀️🤸‍♂️": {
        de_title: "Genuss",
        de_number: 2,
        de_description: "Ausgewälter Genuss",
        en_title: "Enjoyment",
        en_number: 2,
        en_description: "Selective enjoyment"
    },
    "Du liebst es zu kommunizieren. Es fällt dir leicht, Gespräche in Gang zu bringen oder zu vertiefen. Konstruktiv und kreativ kannst du über jegliche Themen sprechen, je tiefer und interessanter die Themen sind, desto engagierter wirst Du. Smalltalk ist ok aber definitiv kein muss. 😋 Empfehlung: Pass ein wenig mehr auf – manche Menschen nutzen deine Gutmütigkeit unbewusst aus. 🤸‍♀️🤸‍♂️": {
        de_title: "Genuss",
        de_number: 3,
        de_description: "Genussmensch, Gesellschaft ist wichtig",
        en_title: "Enjoyment",
        en_number: 3,
        en_description: "Enjoys self, social interaction important"
    },
    "Du liebst die Reflektion, du spürst dein Gegenüber, bist empfindsam und verschmust. Anlehnen, berühren, austauschen, riechen, schmecken faszinieren dich und du liebst es, diese Dinge auch auszuprobieren. 🤗": {
        de_title: "Gefühle",
        de_number: 1,
        de_description: "Träumerisch, mag es romantisch",
        en_title: "Feelings",
        en_number: 1,
        en_description: "Dreamer that loves romance"
    },
    "Manchmal kannst Du bei der Partnerwahl wählerisch sein. Du stellst dich gerne auf die Probe, manchmal spielst du auch gerne, um dich und dein Gegenüber besser zu spüren. Grundsätzlich bist du unkompliziert und offen. 😚": {
        de_title: "Gefühle",
        de_number: 2,
        de_description: "Will hinter die Fassade des Gegenübers schauen",
        en_title: "Feelings",
        en_number: 2,
        en_description: "Wants a deeper understanding of things"
    },
    "Vertrauen, Tiefe, Wissen, Qualität, Offenheit und Kommunikation ist für dich in einer Beziehung ein Muss. Eigenständigkeit, die Entwicklung der eigenen Persönlichkeit eines jeden Partners, ist absolut erwünscht. 🤔": {
        de_title: "Gefühle",
        de_number: 3,
        de_description: "Realistisch, manchmal pragmatisch",
        en_title: "Feelings",
        en_number: 3,
        en_description: "Realistic, mostly pragmatic"
    },
    "Du bist verletzlich und sensibel. Empfindsam und mit Gespür für die kleinen Dinge gehst du durchs Leben. Stets offen, deinen Mitmenschen zu helfen oder sie zu unterstützen. Wenn Du fällst, brauchst du einige Zeit, um dich wieder zu fangen oder aufzustehen. 😇": {
        de_title: "Leben",
        de_number: 1,
        de_description: "Bauchmensch, empfindsam und sensibel",
        en_title: "Life",
        en_number: 1,
        en_description: "Goes with gut feeling, sensitive and emotional"
    },
    "Du kommst ganz gut zurecht mit deinem Leben. Manchmal könntest Du emotional oder rational noch mehr erreichen. In Beziehungen setzt du auf Stabilität und Fairness. Bei der Arbeit bist du stets verlässlich, die Ausnahme bestätigt die Regel. Probleme meisterst du oft ganz gut. 👍": {
        de_title: "Leben",
        de_number: 2,
        de_description: "Ausgeglichen, sucht Stabilität und Fairness",
        en_title: "Life",
        en_number: 2,
        en_description: "Balanced, seeks stability and fairness"
    },
    "Wenn du fällst, stehst Du immer wieder auf. Oft bist du nach aussen der Fels in der Brandung. Auf manche Menschen wirkst du manchmal sehr selbstbewusst oder gar ein wenig stur. Grundsätzlich lässt du dich selten von deinen gewählten Zielen abbringen. 😌": {
        de_title: "Leben",
        de_number: 3,
        de_description: "Kopfmensch, rational, wiederstandsfähig",
        en_title: "Life",
        en_number: 3,
        en_description: "Rational and logic based thinking, not biased by emotions"
    },
    "Bist Du ein Sonnenschein? Zumindest wirkst Du manchmal auf andere so 😃! Es gibt auch Menschen, die dich leider komplett anders einschätzen. Energiegeladen, locker, lustig und lebendig gehst du durch dein Leben. Auf komplizierte Menschen kannst Du gut verzichten. Tipp: Bleib so wie du bist und lass’ dich nicht verbiegen. 🤸‍♀️🤸‍♂️": {
        de_title: "Geist",
        de_number: 1,
        de_description: "Sonnenschein, Geber-Typ",
        en_title: "Spirit",
        en_number: 1,
        en_description: "Full of energy, giver type"
    },
    "Ausgeglichen und beweglich meisterst du den Alltag. Du lässt viel Spielraum nach oben und unten. Besitzergreifende oder manipulierende Menschen schrecken dich ab. Du magst es locker und entspannt und wenn dir Personen auf den Geist gehen, zeigst du das auch. 🤭 Tipp: Du darfst gerne auch noch mutiger sein und ab und zu mehr Risiken eingehen. 🤸‍♀️🤸‍♂️": {
        de_title: "Geist",
        de_number: 2,
        de_description: "Locker und entspannt",
        en_title: "Spirit",
        en_number: 2,
        en_description: "Relaxed, always cool"
    },
    "Fokussiert, verlässlich, zielorientiert und verbal (meist) geradlinig gehst du durch dein Leben. Bei dir weiss man so gut wie immer, woran man ist – wobei sich ab und zu Personen, denen du begegnest, unsicher oder leicht überfordert fühlen. Ziele, die du anpeilst, setzt du so gut wie immer um. Der Team Lead oder die Führungsposition liegen dir meistens. 👨‍🎓👩‍🎓 Tipp: Trotz deiner vielen Stärken darfst du Schwächen gerne noch ein wenig öfter zeigen. 🤸‍♀️🤸‍♂️": {
        de_title: "Geist",
        de_number: 3,
        de_description: "Fokussierter-Typ, geradlinig",
        en_title: "Spirit",
        en_number: 3,
        en_description: "Focused, straight arrow type"
    },
    "Übung macht den Meister 😇. Oft lässt du dir Zeit, um Projekte oder Weiterbildungen in Gang zu bringen; es braucht eine Weile, bis du die nächsten großen Schritte gehst. Entscheidungen zu treffen ist für dich ein Prozess und funktioniert nur selten aus dem Stegreif. Du fühlst dich in deinem vertrauten Umfeld fast immer am wohlsten. 🤗": {
        de_title: "",
        de_number: 0,
        de_description: "",
        en_title: "",
        en_number: 0,
        en_description: ""
    },
    "Vorausschauend und überlegt gehst du Schritt für Schritt vorwärts in deinem Leben 👍. Du bildest dir gerne deine eigene Meinung und wägst ab, was für dich als nächsten Schritt passt. Du bist eher tüchtig, manchmal auch geradezu fleissig, Dein Wissen ist meist fundiert aufgebaut. Bei Dir weiss man, was man hat – oder erwarten darf. Zuverlässigkeit magst du grundsätzlich gerne. ☝️": {
        de_title: "",
        de_number: 0,
        de_description: "",
        en_title: "",
        en_number: 0,
        en_description: ""
    },
    "Menschen mit großen Augen und einem kraftvollen Ausdruck sind meist aufnahmefähig und können viel auf einmal umsetzen 🧠. Du hast eine starke Vorstellungskraft und kannst mit komplexen Themen gut umgehen – und diese oft auch umsetzen. Kulturelle Anlässe, die Vielfalt des Lebens mit seiner Kreativität und Lebendigkeit inspirieren Dich. 💃🕺": {
        de_title: "",
        de_number: 0,
        de_description: "",
        en_title: "",
        en_number: 0,
        en_description: ""
    },
    "Du bist ausgesprochen lebhaft. Oder vielleicht sogar sprunghaft? Du ziehst Angefangenes nicht immer ganz durch, da du dich schnell langweilst oder den Prozess vom Gefühl her schon früh erkennst 🙅‍♀️🙅‍♂️. Was Dich emotional berührt, fasziniert dich dafür umso mehr. Auf andere kannst du ganz schön kreativ... oder manchmal auch crazy wirken. 😜": {
        de_title: "Fokus",
        de_number: 1,
        de_description: "Eher introvertiert, sorgfältige Wortwahl",
        en_title: "Focus",
        en_number: 1,
        en_description: "Often introverted, careful choice of words"
    },
    "Mal impulsiv, mal zurückhaltend diskutierst du mit deinem Umfeld gerne über Erlebtes oder Anstehendes. Du gehst Projekte meist kontrolliert und überlegt an und bist dabei für dein gegenüber stets ein sicherer Wert. Genuss spielt für Dich nicht nur auf dem Teller eine Rolle. ": {
        de_title: "Fokus",
        de_number: 2,
        de_description: "Mal impulsiv, mal zurückhaltend",
        en_title: "Focus",
        en_number: 2,
        en_description: "Mix of impulsiveness and caution"
    },
    "Du bist ein Machertyp oder sehr leidenschaftlich, in dem was du tust. Mit positivem Vorwärtsdrang und viel Energie gehst Du durch dein Leben 🚀. Andere sehen das manchmal nicht so, und es könnte Dir gegenüber auch schon der Vorwurf von Egoismus gefallen sein. Selten bist du passiv oder gar zurückhaltend. Manchmal bist du zu impulsiv oder schnell für andere… Für dich muss halt einfach etwas im Leben laufen, nicht wahr?": {
        de_title: "Fokus",
        de_number: 3,
        de_description: "Extrovertiert, leidenschaftlicher Machertyp",
        en_title: "Focus",
        en_number: 3,
        en_description: "Extrovert, passionate achiever type"
    },
    "Analytisch und fokussiert kannst Du Dinge anpacken und gehst dabei mit gesundem Menschenverstand an die Sache 🤔. Du bist nicht immer bereit, dich emotional komplett zu öffnen – dafür benötigst du Bestätigung und Zeit. Du magst es, konstruktiv zu diskutieren oder den Dingen auf den Grund zu gehen. Tipp: Sei jederzeit offen für Inspiration, du kannst dabei nur gewinnen. 🤸‍♀️🤸‍♂️": {
        de_title: "",
        de_number: 0,
        de_description: "",
        en_title: "",
        en_number: 0,
        en_description: ""
    },
    "Kumpelhaft und locker geht es für dich am Einfachsten. Du hast öfters ein offenes Ohr für andere und gehst emotionale Themen ruhig und besonnen an 🙃. Auf dich ist fast immer Verlass und du sorgst bei den meisten anderen Menschen für eine gute Stimmung. Manchmal bist Du die Schulter zum Anlehnen, manchmal einfach nur ein guter, verständnisvoller Zuhörer. Tipp: Vorsicht, sei nicht allzu verschwenderisch mit deiner Energie. 🤸‍♀️🤸‍♂️": {
        de_title: "",
        de_number: 0,
        de_description: "",
        en_title: "",
        en_number: 0,
        en_description: ""
    },
    "Seelische Tiefe und Mitgefühl sind bei dir angeboren. Menschen und Tieren, denen es nicht gut geht, gehen dir ans Herz ❤️. Empathisch meisterst Du deinen Alltag und nimmst am Leben so richtig teil. Helfen, unterstützen und Außergewöhnliches im emotionalen und zwischenmenschlichen zu leisten ist für dich einfach selbstverständlich. Tipp: Wo viel gegeben wird, kommt auch viel zurück. 🤸‍♀️🤸‍♂️": {
        de_title: "",
        de_number: 0,
        de_description: "",
        en_title: "",
        en_number: 0,
        en_description: ""
    }
}
