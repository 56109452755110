import React, { useContext, useState } from 'react'
import { Button, Col, Progress, Row, Slider, Upload } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { storage } from '../../services/firebase'
import { $, key } from '../../services/localization'
import { isImage } from '../../helpers/imageHelper'
import { UserContext } from '../../providers/UserProvider'
import { Redirect } from 'react-router-dom'
import axios from '../../helpers/axios'
import { handleError } from '../../helpers/handleError'
import { mappings } from './mappings'
import approved_photo from '../../assets/correct.png'
import dont_smile_photo from '../../assets/no_smile.png'
import download_icon from '../../assets/download_icon.png'
import no_glasses_photo from '../../assets/no_glasses.png'
import logo from '../../assets/logo.png'
import Resizer from 'react-image-file-resizer'
import Loading from '../Loading'
import './home.less'

const HomeContainer = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [result, setResult] = useState()

  const user = useContext(UserContext)

  const onUploadPicture = async (process) => {
    try {
      setIsLoading(true)
      if (isImage(process.file)) {
        // First resize image before uploading
        Resizer.imageFileResizer(
          process.file,
          800,
          undefined,
          'JPEG',
          70,
          0,
          // Callback when Image is resized
          uri => {
            // Upload to Firebase
            const storageRef = storage.ref(`matchME-POC/${new Date().getTime()}_${process.file.name}`)
            const task = storageRef.put(uri)
            task.on('state_changed',
              (snapshot) => {
                process.onProgress({ percent: Math.round(snapshot.bytesTransferred / snapshot.totalBytes * 100) })
              },
              (error) => {
                // Error when uploading
                process.onError($(key.errorGeneric))
                setIsLoading(false)
                handleError(error, $(key.errorGeneric))
              },
              async () => {
                // When upload is successful, send to closeup API for processing
                try {
                  const pictureURL = await storageRef.getDownloadURL()
                  const response = await axios({
                    method: 'post',
                    url: 'https://closeup.azurewebsites.net/api/process',
                    data: {
                      language: 'de',
                      userId: 0,
                      source: 'poc',
                      pictureUrl: pictureURL
                    }
                  })

                  // Check if there is an error in the API response
                  if (response.data.error) {
                    showErrorMessage(response.data.error)
                    setIsLoading(false)
                  } else {
                    // Map to the short descriptions, and set the values.
                    response.data.mapped = []
                    for (let characteristic of response.data.analysisResult) {
                      const auxMapped = {
                        de_title: mappings[characteristic]?.de_title,
                        de_number: mappings[characteristic]?.de_number,
                        de_description: mappings[characteristic]?.de_description,
                        en_title: mappings[characteristic]?.en_title,
                        en_number: mappings[characteristic]?.en_number,
                        en_description: mappings[characteristic]?.en_description
                      }
                      if (auxMapped.de_description && auxMapped.en_description) {
                        response.data.mapped.push(auxMapped)
                      }
                    }
                    setIsLoading(false)
                    setResult(response.data)
                  }
                } catch (error) {
                  console.log(error)
                  showErrorMessage(error.response?.data?.error)
                  setIsLoading(false)
                }
              }
            )
          },
          'blob'
        )
      }
    } catch (error) {
      setIsLoading(false)
      handleError(error)
    }
  }

  const showErrorMessage = (errorCode) => {
    switch (errorCode) {
      case 'NO_FACE':
        handleError(null, $(key.errorNoFace))
        break
      case 'MULTIPLE_FACES':
        handleError(null, $(key.errorMultipleFaces))
        break
      case 'ERROR_DETECTING_FACE':
        handleError(null, $(key.errorDetectingFace))
        break
      default:
        handleError(null, $(key.errorGeneric))
        break
    }
  }

  const photoInstructions = () => {
    return (
      <div className="home-instructions">
        <Row align="center"
          className="instructions-top">
          <Col span="24">
            <Row className="logo-header"
              align="center">
              <Col>
                <Row align="center" >
                  <img src={logo}
                    className="logo"
                    alt="matchME Logo" />
                </Row>
              </Col>
            </Row>
            <Row className="description"
              align="center" justify="center">
              <Col xs={24}
                lg={12}>
                {$(key.matchMeDescription)}
              </Col>
            </Row>

            <Row className="upload"
              align="center">
              <Col xs={20}
                lg={8}>
                <Upload action={null}
                  className="upload-container"
                  customRequest={onUploadPicture}
                  showUploadList={false}
                  accept='image/*'>
                  <Button className="upload-button"
                    disabled={isLoading}>
                    <Row className="button-icon"
                      align="center">
                      {
                        isLoading ?
                          <LoadingOutlined />
                          :
                          <img src={download_icon}
                            className="upload-photo-icon"
                            alt="Upload" />
                      }
                    </Row>
                    <Row className="button-label"
                      align="center">
                      {$(key.uploadButtonLabel)}
                    </Row>
                  </Button>
                </Upload>
              </Col>
            </Row>
            <Row className="photos-instructions" align="center">
              <Col xs={24}
                lg={10}>
                <Row align="space-around"
                  gutter="20">
                  <Col span={8}>
                    <Row className="instruction-container" align="center">
                      <img src={approved_photo}
                        className="approved-photo"
                        alt="Approved" />
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Row className="instruction-container" align="center">
                      <img src={no_glasses_photo}
                        className="no-glasses-photo"
                        alt="No glasses" />
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Row className="instruction-container" align="center">
                      <img src={dont_smile_photo}
                        className="dont-smile-photo"
                        alt="Don't smile" />
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

          </Col>
        </Row>
      </div>
    )
  }

  const resultsDisplay = () => {
    return (
      <div className="home-results">
        <Row className="logo-header"
          align="center">
          <Col>
            <Row align="center" >
              <img src={logo}
                className="logo"
                alt="matchME Logo" />
            </Row>
          </Col>
        </Row>
        <Row align="center">
          <Col xs={24}
            lg={16}>
            <Row className="results-display"
              align="space-around"
              gutter="25">
              <Col span={8}>
                <Row align="center">
                  <img src={result.pictureUrl}
                    className="uploaded-photo"
                    alt="Your upload" />

                </Row>
                <Row align="center">
                  <Upload action={null}
                    className="upload-container"
                    customRequest={onUploadPicture}
                    accept='image/*'
                    showUploadList={false}>
                    <Button className="upload-button"
                      disabled={isLoading}>
                      <Row className="button-icon"
                        align="center">
                        {
                          isLoading ?
                            <LoadingOutlined />
                            :
                            <img src={download_icon}
                              className="upload-photo-icon"
                              alt="Upload" />
                        }
                      </Row>
                      <Row className="button-label"
                        align="center">
                        {$(key.newPortrait)}
                      </Row>
                    </Button>
                  </Upload>
                </Row>
              </Col>
              <Col span={16}
                className="characteristics-list">
                <Row justify="start"
                  gutter="20"
                  className="title">
                  <Col span={12}>
                    {$(key.characteristicsDe)}

                  </Col>
                  <Col span={12}>
                    {$(key.characteristicsEn)}
                  </Col>
                </Row>
                {
                  result?.mapped?.map((characteristic, index) => {
                    return (
                      <Row justify="start"
                        key={index}
                        gutter="20">
                        <Col span={24}>
                          <Row className="characteristic-title"
                            gutter="20">
                            <Col span={12}>
                              {characteristic.de_title}
                            </Col>
                            <Col span={12}>
                              {characteristic.en_title}
                            </Col>
                          </Row>
                          <Row className="characteristic-description"
                            gutter="20">
                            <Col span={12}>
                              {characteristic.de_description}
                            </Col>
                            <Col span={12}>
                              {characteristic.en_description}
                            </Col>
                          </Row>
                          <Row className="progress-bar"
                            gutter="20">
                            <Col span={12}>
                              <Progress
                                showInfo={false}
                                strokeColor={{
                                  '0%': '#27384F',
                                  '100%': '#7A8CA4',
                                }}
                                percent={characteristic.de_number * 100 / 3}
                              />
                            </Col>
                            <Col span={12}>
                              <Progress
                                showInfo={false}
                                strokeColor={{
                                  '0%': '#27384F',
                                  '100%': '#7A8CA4',
                                }}
                                percent={characteristic.de_number * 100 / 3}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )
                  })
                }
                <Row justify="start"
                  gutter="20"
                  className="explanation">
                  <Col span={12}>
                    <Row className="explanation-title">
                      {$(key.explanationTitleDe)}
                    </Row>
                    <Row className="explanation-description">
                      <Col span={12}>
                        <Row align="left">
                          {$(key.lessPronouncedDe)}

                        </Row>
                      </Col>
                      <Col span={12}>
                        <Row align="end">
                          {$(key.veryPronouncedDe)}
                        </Row>
                      </Col>
                    </Row>
                    <Row className="explanation-slider">
                      <Col span={24}>
                        <Slider range
                          tipFormatter={null}
                          defaultValue={[1, 100]}
                          disabled={true} />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row className="explanation-title">
                      {$(key.explanationTitleEn)}
                    </Row>
                    <Row className="explanation-description">
                      <Col span={12}>
                        <Row align="left">
                          {$(key.lessPronouncedEn)}

                        </Row>
                      </Col>
                      <Col span={12}>
                        <Row align="end">
                          {$(key.veryPronouncedEn)}
                        </Row>
                      </Col>
                    </Row>
                    <Row className="explanation-slider">
                      <Col span={24}>
                        <Slider range
                          tipFormatter={null}
                          defaultValue={[1, 100]}
                          disabled={true} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

      </div>
    )
  }

  if (user === null) {
    return (
      <Loading />
    )
  } else if (user) {
    return (
      result?.analysisResult ?
        resultsDisplay()
        :
        photoInstructions()
    )
  } else {
    return (
      <Redirect to="/" />
    )
  }
}

export default HomeContainer
