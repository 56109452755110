import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import HomeContainer from './components/Home/HomeContainer'
import StandalonePasswordLoginComponent from './components/Login/StandAlonePasswordLoginComponent';
import UserProvider from "./providers/UserProvider";
import './App.less';

function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <UserProvider>
        <Switch>
          {/* Website */}
          <Route exact
            path='/'
            component={StandalonePasswordLoginComponent} />
          <Route exact
            path='/demo'
            component={HomeContainer} />
        </Switch>
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
